<template>
    <div class="w-100 form-row">
          <div class="col-12 mb-3">
              <button type="button" class="btn btn-primary" @click="run" :disabled="processing">
                  {{tr('View Activity')}}
              </button>
          </div>
           <table class="table table-striped  table-sm table-bordered col-12" v-if="values">
              <thead>
                  <tr class="table-secondary">
                      <th><span>{{tr('From')}}</span></th>
                      <th><span>{{tr('To')}}</span></th>
                      <th><span>{{tr('Subject')}}</span></th>
                      <th><span>{{tr('Last Date Event')}}</span></th>
                      <th><span>{{tr('Opens Count')}}</span></th>
                      <th><span>{{tr('Status')}}</span></th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(c, i) in values.messages" class="service-card" >
                      <td>{{c.from_email}}</td>
                      <td>{{c.to_email}}</td>
                      <td>{{c.subject}}</td>
                      <td>{{c.last_event_time | toLocalDateTime}}</td>
                      <td>{{c.opens_count}}</td>
                      <td>{{c.status}}</td>
                  </tr>
              </tbody>
          </table>
          <div class="col-12 mb-3">
              <button type="button" class="btn btn-primary" @click="runBlocks" :disabled="processingBlocks">
                  {{tr('View Blocks')}}
              </button>
          </div>
           <table class="table table-striped  table-sm table-bordered col-12" v-if="valuesBlocks">
              <thead>
                  <tr class="table-secondary">
                      <th><span>{{tr('Email')}}</span></th>
                      <th><span>{{tr('Created')}}</span></th>
                      <th><span>{{tr('Reason')}}</span></th>
                      <th><span>{{tr('Status')}}</span></th>
                      <th><span></span></th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(c, i) in valuesBlocks" class="service-card" >
                      <td>{{c.email}}</td>
                      <td>{{c.created}}</td>
                      <td>{{c.reason}}</td>
                      <td>{{c.status}}</td>
                      <td>
                          <button type="button" class="btn btn-primary" @click="clearBlock(c.email)">
                              {{tr('Clear')}}
                          </button>
                      </td>
                  </tr>
              </tbody>
          </table>

    </div>
</template>


<script>
export default {
    name: 'mail-activity',
    props: [ "record", "field" , "langCode"],
    components: {},
    computed: {
    },
    data () {
        return {
            values: null,
            processing: false,
            valuesBlocks: null,
            processingBlocks: false
        }
    },
    methods: {
        async run () {
            this.processing = true;
            let res = await api.get('/api/mail/activity/' + this.record.id);
            if (res) {
                this.values = JSON.parse(res);
            }
            this.processing = false;
        },
        async runBlocks () {
            this.processingBlocks = true;
            let res = await api.get('/api/mail/blocks/' + this.record.MailTo);
            if (res) {
                this.valuesBlocks = res;
            }
            this.processingBlocks = false;
        },
        async clearBlock (email) {
            let res = await api.get('/api/mail/clear_blocks/' + email);
            if (res) {
                alert(res);
            }
        }
    }
};
</script>

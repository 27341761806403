<template>
    <div>
        <div class="form-row mx-5 mb-3 ">
            <search-box v-model="searchBox" label="Search periods" class="col-6"></search-box>
            <search-box v-model="searchBoxServices" label="Search services" class="col-6"></search-box>
        </div>
        <div class="form-row ml-3" v-if="!processing">
            <div class="col-md-12" v-if="periods">
                <div v-for="(period, i) in periods" class="col-md-11 card-header px-0 py-2 mb-1 ml-4"
                  :class="getBGClass(i)" v-if="showPeriod[i]">
                    <div class="py-0 mx-3">
                        <label class="float-left mb-0 cursor-pointer"  @click="showServiceCost(i)" :class="{'text-danger': periodsError[i]}">
                            {{ period.StartDate | toDate }} {{tr('to')}} {{ period.EndDate | toDate }} {{ period.Comment }}
                        </label>
                        <i class="add-item cursor-pointer ml-3" @click="removePeriod(i)">
                            <font-awesome-icon icon="trash-alt"/>
                        </i>
                        <i class="add-item cursor-pointer ml-3" @click="copy(i)">
                            <font-awesome-icon icon="copy"/>
                        </i>

                    </div>
                </div>
            </div>
            <div class="col-md-10 mt-2">
                <i class="add-item cursor-pointer" @click="addNewPeriod">
                    <font-awesome-icon icon="plus-circle"/>
                </i>
            </div>
            <div class="col-md-2 mt-2">
                <div class="form-group">
                    <div class="form-check">
                        <input v-model="includeClosed" name="includeClosed" class="form-check-input" type="checkbox" id="includeClosed" @change="setPeriods">
                        <label class="smaller form-check-label">{{tr('Show Archived')}}</label>
                    </div>
                </div>
            </div>
        </div>
        <supplier-service-cost
            v-if="currentPeriod"
            :record="record"
            :period.sync="currentPeriod"
            @close="closeSupplierServiceCost"
            @updateRecord="updateRecord"
        ></supplier-service-cost>

    </div>
</template>

<script>
const supplierServiceCost = importVueComp('components/supplier', 'SupplierServiceCost');
import { mapState } from "vuex";
const uuidv1 = require('uuid/v1');
//const moment = require("moment");
export default {
    name: 'supplier-service-cost-list',
    props: ['record'],
    components: {
        'supplier-service-cost': supplierServiceCost,
    },
    data () {
        return {
            includeClosed: false,
            fieldOptions: {},
            periods: [],
            serviceTypes: null,
            costBy: null,
            services: null,
            newService: false,
            newService: false,
            newBase: false,
            selectCategory: false,
            newPeriodCategory: null,
            currentPeriod: null,
            currentPeriodId: null,
            currencies: null,
            CostByOptions: [
                {value: 'BASE_PERSON', label: 'Per Person, variable on base'},
                {value: 'PERSON', label: 'Per Person, fixed'},
                {value: 'SERVICE', label: 'Per Service, fixed'},
                {value: 'BASE_SERVICE', label: 'Per Service, variable on base'}
            ],
            searchBox: null,
            searchBoxServices: null
        };
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            processing: state => state.main.processing,
            recordStore: state => state.main.record,
        }),
        currencyList () {
            if (!this.currencies) return [];
            let res = [];
            for (let c of this.currencies) {
                res.push({ label: c.id, value: c.id });
            }
            return res;
        },
        hasCategories () {
            if (
                _.filter(this.record.ServiceCategories, c => c.Closed != true)
                    .length > 0
            ) {
                return true;
            }
            if (this.services) {
                for (let s of this.services) {
                    if (
                        _.filter(s.ServiceCategories, c => c.Closed != true)
                            .length > 0
                    ) {
                        return true;
                    }
                }
            }
            return false;
        },
        showPeriod () {
            let res = {};
            for (let i in this.periods) {
                let found;
                let period = this.periods[i];
                if (!this.filter(period.Comment, this.searchBox)) continue;
                if (!period) continue
                if (!this.filterService(period.table.rows)) continue;
                if (period.table && period.table.rows) {
                    for (let j in period.table.rows) {
                        let row = period.table.rows[j];
                        if (row.Delete) continue;
                        if (this.includeClosed){
                            found = true;
                        }
                        else{
                            if (!row.Closed.value) {
                                found = true;
                            }
                        }
                    }
                }
                res[i] = found;
            }
            return res;
        },
        periodsError () {
            let res = {};
            if (!this.record.periodError) return res;
            for (let i in this.periods) {
                let p = this.periods[i];
                for (let period of this.record.periodError) {
                    if (!period) continue
                    if (period._id && p._id && period._id != p._id) continue;
                    if (period._id && p._id && period._id == p._id) {
                        res[i] = true;
                    } else if (period.StartDate==p.StartDate && period.EndDate==p.EndDate) {
                        res[i] = true;
                    }
                }
            }
            return res;
        },

    },
    async mounted () {
        if (!this.record.id) return;
        this.currencies = await api.getTable('currency');
        api.setProcessing(true)
        await this.getServices();
        if (this.currencies) {
            await this.setPeriods();
        }
        api.setProcessing(false)
    },
    watch: {
        currencies () {
            if (this.currencies) {
                this.setPeriods();
            }
        },
    },
    methods: {
        filter (data, searchData) {
            if (!searchData) return true;
            if (!data) return false;
            if (searchData.length < 3) return true;
            let searchBox = searchData.replace(/\./g, ' ')
            let values = searchBox.split(' ')
            for (let value of values){
                let re = new RegExp(tools.normalize(value), 'i')
                let m = tools.normalize(data).match(re)
                if (m) return true;
            };
            return false;
        },
        filterService (data) {
            if (!this.searchBoxServices) return true;
            if (!data) return false;
            for (let rowId in data) {
                let row = data[rowId];
                let service = row.values.find(v => v.fieldName == 'Name');
                if (this.filter(service.value, this.searchBoxServices)) return true;
            }
            return false;
        },
        async getServices () {
            let res = await api.get("/api/service/", {
                SupplierId: this.record.SupplierId? this.record.SupplierId: this.record.id,
                Relations: true,
                IncludeClosed: true
            });
            if (res) this.services = res;
        },
        async setPeriods () {
            let res = await api.get("/api/servicecost/get_all", {
                SupplierId: this.record.SupplierId? this.record.SupplierId: this.record.id,
                IncludeClosed: this.includeClosed    
            });
            if (res) {
                let periods = [];
                for (let p of res) {
                    let period = _.find(
                        periods,
                        c => c.StartDate == p.StartDate && c.EndDate == p.EndDate && p.Comment == c.Comment &&
                          p.ChildDiscount == c.ChildDiscount
                    );
                    if (!period) {
                        period = {
                            _id: uuidv1(),
                            StartDate: p.StartDate,
                            EndDate: p.EndDate,
                            Comment: p.Comment,
                            BlackOut: p.BlackOut,
                            TourLeader: p.TourLeader,
                            ChildDiscount: p.ChildDiscount,
                            Commission: p.Commission,
                            WeekDays: p.WeekDays,
                            ByWeekDay: p.ByWeekDay,
                            rows: []
                        };
                        periods.push(period);
                    }
                    period.rows.push(p);
                }
                for (let period of periods) {
                    this.createTable(period, period.rows);
                }
                this.periods = Object.assign([], periods);
                api.setProcessing(false)
            }
        },
        async addNewPeriod () {
            await this.addPeriod();
        },
        async addServicesToPeriod (period, categoryId) {
            if (!this.services) {
                await this.getServices();
            }
            if (!this.services) return;
            for (let r of this.services) {
                let row = { values: [] };
                row.id = {
                    fieldName: "id",
                    value: null,
                    editable: false,
                    hidden: true
                };
                row.Closed = {
                    fieldName: "Closed",
                    value: null,
                    editable: false,
                    hidden: true
                };
                row.syncVersion = {
                    fieldName: "syncVersion",
                    value: null,
                    editable: false,
                    hidden: true
                };
                row.values.push({
                    fieldName: "Name",
                    value: r.Name,
                    editable: false,
                    width: "300px",
                    sort: -4,
                    className: 'width-300',
                });
                if (r.ServiceType) {
                    row.values.push({
                        fieldName: "ServiceType",
                        value: r.ServiceType.Name,
                        editable: false,
                        sort: -3
                    });
                }
                row.ServiceId = {
                    fieldName: "ServiceId",
                    value: r.id,
                    editable: false,
                    hidden: true
                };
                if (this.hasCategories) {
                    row.values.push({
                        fieldName: "ServiceCategoryId",
                        value: categoryId,
                        editable: true,
                        editor: "select",
                        addBlank: true,
                        fieldOptions: {
                            ServiceCategoryId: this.getCategories(r.id)
                        },
                        sort: -2
                    });
                }
                row.values.push({
                    fieldName: "CurrencyId",
                    value: r.CurrencyId,
                    editable: true,
                    sort: -1,
                    editor: "select",
                    fieldOptions: { CurrencyId: this.currencyList }
                });
                row.values.push({
                    fieldName: "CostBy",
                    value: r.CostBy,
                    editable: true,
                    sort: -1.5,
                    editor: "select",
                    fieldOptions: {CostBy: this.CostByOptions}
                });
                this.addBasesToRow(row, r, r.ServiceType.Name, period);
                row.modified.value = true;
                row.values.sort(function(a, b) {
                    return a.sort - b.sort;
                });
                period.table.rows[Object.keys(period.table.rows).length] = row;
            }
        },
        async addPeriod (categoryId) {
            let period = {};
            this.startTable(period, []);
            await this.addServicesToPeriod(period, categoryId);
            this.periods.push(period);
            this.periods = Object.assign([], this.periods);
            period.table = Object.assign({}, period.table);
        },
        async updateRecord () {
            if (this.currentPeriodId !== null) {
                this.periods[this.currentPeriodId] = this.currentPeriod
            };
            let records = [];
            let c = 0;
            for (let p in this.periods) {
                if (!this.periods[p]) continue;
                this.periods[p].rowNr = c;
                c += 1;
                for (let i in this.periods[p].table.rows) {
                    let row = this.periods[p].table.rows[i];
                    if (row.modified && row.modified.value) {
                        let costBy = _.find(row.values, s => s.fieldName == "CostBy").value
                        let fields = {
                            id: row.id.value,
                            syncVersion: row.syncVersion.value,
                            original_id: row.id.value,
                            ServiceId: row.ServiceId.value,
                            StartDate: this.periods[p].StartDate,
                            EndDate: this.periods[p].EndDate,
                            Closed: this.periods[p].Closed,
                            Delete: row.Delete,
                            Comment: this.periods[p].Comment,
                            BlackOut: this.periods[p].BlackOut,
                            TourLeader: this.periods[p].TourLeader,
                            Commission: this.periods[p].Commission,
                            WeekDays: this.periods[p].WeekDays,
                            ByWeekDay: this.periods[p].ByWeekDay,
                            ChildDiscount: this.periods[p].ChildDiscount,
                            Name: _.find(row.values, s => s.fieldName == "Name").value,
                            Data: {},
                            CurrencyId: _.find(row.values, s => s.fieldName == "CurrencyId").value,
                            CostBy: costBy
                        };
                        if (this.hasCategories) {
                            let categoryField = _.find(
                                row.values,
                                s => s.fieldName == "ServiceCategoryId"
                            );
                            if (categoryField)
                                fields.ServiceCategoryId = categoryField.value;
                        }
                        for (let f in row.values) {
                            let field = row.values[f];
                            if (field.fieldName && field.isBase) {
                                if (costBy == "BASE_PERSON" || field.value) field.editable = true;
                                if (costBy == "BASE_SERVICE" || field.value) field.editable = true;
                                fields.Data[field.fieldName] = field.value;
                            }
                            if (field.fieldName && (field.fieldName == 'ADT' || field.fieldName == 'CHD')) {
                                if (costBy == "PERSON" || field.value) {
                                    field.editable = true;
                                } else {
                                    field.editable = false;
                                }
                            }
                        }
                        records.push(fields);
                    }
                }
            }
            let record = this.record;
            record.ServiceCost = records;
            record.periods = this.periods;
            this.recordStore.periods = Object.assign([], this.periods);
            this.$emit("update:record", record);
            let args = { fieldName: 'ServiceCost', value: records};
            await this.$store.dispatch('callAction', {options: null, action: 'setValue', args: args})
        },
        addBasesToRow (row, r, servicetype, period) {
            for (let base of period.bases) {
                var value = null;
                if (r.Data) {
                    value = r.Data[base];
                }
                let canEdit = false;
                let costBy = r.CostBy? r.CostBy: r.ServiceType.CostBy;
                if ((costBy == "BASE_PERSON" && base != "ADT" && base != "CHD") || value) canEdit = true;
                if ((costBy == "BASE_SERVICE" && base != "ADT" && base != "CHD") || value) canEdit = true;
                if ((costBy == "SERVICE" && base == "1") || value) canEdit = true;
                if ((costBy == "PERSON" && (base == "ADT" || base == "CHD")) || value) canEdit = true;
                row.values.push({
                    fieldName: base,
                    value: value,
                    editable: canEdit,
                    editor: "number",
                    width: "40px",
                    isBase: true,
                    sort: this.getBaseSort(base)
                });
            }
            row.modified = { editable: false, hidden: true, value: false };
            row.hidden = { editable: false, hidden: true, value: false };
        },
        startTable (period, rows) {
            period._id = uuidv1();
            //period.bases = ["1", "2", "3", "4"];
            period.bases = [];
            period.table = [];
            period.table.rows = {};
            if (!this.hasCategories) {
                period.table.header = [
                    { value: tr('Service'), sort: -4, className: 'width-300'},
                    { value: tr('Type'), sort: -3 },
                    { value: tr('Currency'), sort: -1 },
                    { value: tr('Cost'), sort: -1.5 }
                ];
            } else {
                period.table.header = [
                    { value: tr('Service'), sort: -4, className: 'width-300' },
                    { value: tr('Type'), sort: -3 },
                    { value: tr('Category'), sort: -2 },
                    { value: tr('Currency'), sort: -1 },
                    { value: tr('Cost'), sort: -1.5 }
                ];
            }
            period.header_cols = period.table.header.length;
            for (let r of rows) {
                for (let key in r.Data) {
                    if (
                        //r.Data[key] &&
                        key != "ADT" &&
                        key != "CHD" &&
                        period.bases.indexOf(key) == -1
                    ) {
                        period.bases.push(key);
                    }
                }
            }
            period.bases.sort();
            period.bases.splice(0, 0, "ADT");
            period.bases.splice(1, 0, "CHD");
            for (let base of period.bases) {
                period.table.header.push({
                    value: base,
                    sort: this.getBaseSort(base)
                });
            }
            period.table.header.sort(function(a, b) {
                return a.sort - b.sort;
            });
        },
        createTable (period, rows) {
            this.startTable(period, rows);
            for (let r of rows) {
                let row = { values: [] };
                row.id = {
                    fieldName: "id",
                    value: r.id,
                    editable: false,
                    hidden: true
                };
                row.Closed = {
                    fieldName: "Closed",
                    value: r.Closed,
                    editable: false,
                    hidden: true
                };
                row.syncVersion = {
                    fieldName: "syncVersion",
                    value: r.syncVersion,
                    editable: false,
                    hidden: true
                };
                row.values.push({
                    fieldName: "Name",
                    value: r.Name,
                    editable: false,
                    width: "300px",
                    sort: -4,
                    className: 'width-300'
                });
                row.values.push({
                    fieldName: "ServiceType",
                    value: r.ServiceType,
                    editable: false,
                    width: "100px",
                    sort: -3
                });
                row.ServiceId = {
                    fieldName: "ServiceId",
                    value: r.ServiceId,
                    editable: false,
                    hidden: true
                };
                if (this.hasCategories) {
                    row.values.push({
                        fieldName: "ServiceCategoryId",
                        value: r.ServiceCategoryId,
                        editable: true,
                        editor: "select",
                        addBlank: true,
                        fieldOptions: {
                            ServiceCategoryId: this.getCategories(r.ServiceId)
                        },
                        sort: -2
                    });
                }
                row.values.push({
                    fieldName: "CurrencyId",
                    value: r.CurrencyId,
                    editable: true,
                    sort: -1,
                    editor: "select",
                    fieldOptions: { CurrencyId: this.currencyList }
                });
                row.values.push({
                    fieldName: "CostBy",
                    value: r.CostBy,
                    editable: true,
                    sort: -1.5,
                    editor: "select",
                    fieldOptions: {CostBy: this.CostByOptions}
                });
                this.addBasesToRow(row, r, r.ServiceType, period);
                row.values.sort(function(a, b) {
                    return a.sort - b.sort;
                });
                period.table.rows[Object.keys(period.table.rows).length] = row;
            }
            period.table = Object.assign({}, period.table);
        },
        getBaseSort (base) {
            let isRange = base.indexOf(":") > -1;
            if (isRange) {
                return parseFloat(base.split(":")[0]) + parseFloat(base.split(":")[1]) / 100;
            }
            return parseInt(base);
        },
        getCategories (ServiceId) {
            let res = [];
            let service = _.find(this.services, c => c.id == ServiceId);
            if (service) {
                let found = false;
                if (service.ServiceCategories.length > 0) {
                    for (let c of service.ServiceCategories) {
                        if (c.Closed) continue;
                        res.push({ label: c.Name, value: c.id });
                        found = true;
                    }
                }
                if (!found && this.record.ServiceCategories && this.record.ServiceCategories.length > 0) {
                    for (let c of this.record.ServiceCategories) {
                        if (c.Closed) continue;
                        res.push({ label: c.Name, value: c.id });
                    }
                }
            }
            return res;
        },
        async removePeriod (p) {
            for (let i in this.periods[p].table.rows) {
                this.periods[p].table.rows[i].modified.value = true;
                this.periods[p].table.rows[i].Delete = true;
                this.periods[p].Delete = true;
            }
            this.periods = Object.assign([], this.periods);
            await this.updateRecord();
        },
        copy (i) {
            let newPeriod = _.cloneDeep(this.periods[i]);
            for (let i in newPeriod.table.rows) {
                let row = newPeriod.table.rows[i];
                row.id = {
                    fieldName: "id",
                    value: null,
                    editable: false,
                    hidden: true
                };
            }
            newPeriod.id = null;
            newPeriod.StartDate = null;
            newPeriod.EndDate = null;
            newPeriod.Comment = null;
            newPeriod.Closed = null;
            newPeriod.BlackOut = null;
            newPeriod.TourLeader = null;
            newPeriod.Commission = null;
            newPeriod.ByWeekDay = false,
            newPeriod.WeekDays = {};
            newPeriod.ChildDiscount = null,
            this.periods.push(newPeriod);
            this.$emit('update:periods', Object.assign([],this.periods))
        },

        getPeriodError (p) {
            if (!this.recordStore.periodError) return "";
            for (let period of this.recordStore.periodError) {
                if (
                    period.StartDate == p.StartDate &&
                    period.EndDate == p.EndDate
                ) {
                    return "period-error";
                }
            }
            return "";
        },
        showServiceCost (i) {
            this.currentPeriod = this.periods[i];
            this.currentPeriodId = i;
        },
        getBGClass (dayId) {
            if (dayId % 2 == 0) return 'bg-silver';
            return '';
        },
        closeSupplierServiceCost () {
            this.currentPeriod = null;
            this.currentPeriodId = null;
        }
    }
};
</script>

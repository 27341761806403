<template>
    <div class="form-row col-md-12 pl-0" v-if="quote" @click="focusOn" :class="{'service-quantity': recordStore.QuoteBy=='QUANTITY'}">
        <div class="form-group service-row col-md-1 mt-0 ml-0 text-center pl-3">
            <span class="text-nowrap">{{date}}</span>&nbsp
            <span class="text-nowrap">{{tr('Day')}} {{dayNr+1}}</span>
        </div>
        <div class="form-group service-row mt-0" :class="hotelCols" @contextmenu="handleClickName($event)">
            <div v-if="salesActions" class="pl-2">
              <a href="#" data-toggle="modal"
                  :id="getId()"
                  class=""
                  :class="'hotel-day-' + roomQuote.dayNr + '-' + baseId"
                  data-target="#select-hotel-modal"
                  @click="editHotel"
              >
                {{roomQuote.hotelName}} {{roomQuote.days}} {{tr('Nights')}} {{rooms}} {{roomName}}
                {{roomQuote.roomCategoryName}}
                <i class="text-danger" v-if="room.TourLeader">TL</i>
                <i class="text-danger" v-if="room.SingleSupplement">SGL SUP</i>
              </a>
            </div>
            <div v-else class="pl-2">
                {{tr('Day')}} {{roomQuote.dayNr+1}}:
                {{roomQuote.hotelName}} {{roomQuote.days}} {{tr('Nights')}} {{rooms}} {{roomName}}
                {{roomQuote.roomCategoryName}}
                <i class="text-danger" v-if="room.TourLeader">TL</i>
                <i class="text-danger" v-if="room.SingleSupplement">SGL SUP</i>
            </div>
            <i class="text-danger" v-if="room.Status=='CANCELED'">
              {{tr('Canceled')}}
            </i>

        </div>
        <div class="form-row d-flex justify-content-center pl-5" :class="pricesCols">
            <div class="form-row col-md-12">
                <div class="form-group col col-input service-row">
                    <input v-model="quote.Include" type="checkbox"
                        :disabled="!canEditInclude"
                        @change="change('Include')"
                        @contextmenu="handleClick($event)"
                        class="form-check-input" v-if="edit"/>
                    <number-input :v.sync="quote.Cost"
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :addClass="{'border-danger': quote.invalid.Cost, 'border-warning': quote.Modified, 'line-through': room.skipQuoteCanceled}"
                        :id="'H-' + baseId + '-' + roomQuote.dayNr + '-' + roomIdx + '-Cost' "
                        v-if="quote" :currency="recordStore.Currency" :disabled="!canEdit"
                        @change="change('Cost')"
                    ></number-input>
                    <i class="cost-alert cursor-pointer" v-if="costError" >
                      <font-awesome-icon icon="exclamation" @click="setOver"/>
                      <div v-if="upOver" @click="setOverLeave" class="cost-value cursor-pointer">
                          {{tr('Current cost')}}: {{costValue | formatNumber({c: recordStore.CurrencyId})}}
                      </div>
                    </i>

                </div>
                <div class="form-group col col-input service-row">
                    <number-input :v.sync="quote.Markup"
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :addClass="quote.MarkupModified? 'border-warning': ''"
                        @change="change('Markup')"
                        v-if="quote" :currency="recordStore.Currency" :disabled="!canEdit"></number-input>
                </div>
                <div class="form-group col col-input service-row">
                    <number-input :v.sync="quote.Price"
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :addClass="{'border-danger': roomQuote.isErrorPrice, 'border-warning': quote.PriceModified, 'border-danger': quote.invalid.Price, 'line-through': room.skipQuoteCanceled}"
                        :id="'H-' + baseId + '-' + roomQuote.dayNr + '-' + roomIdx + '-Price' "
                        @change="change('Price')"
                        v-if="quote" :currency="recordStore.Currency" :disabled="!canEdit"></number-input>
                </div>
                <div class="form-group col col-input service-row"
                    v-if="!user.AgencyId && !room.Room.Checkin && roomQuote.hotelType=='NORMAL' && recordStore.QuoteBy!='QUANTITY'">
                    <number-input :v.sync="quote.RoomCost" @change="setRoomCost()"
                        :changeStyle="true"
                        class="my-0 mx-1"
                        v-if="quote" :currency="recordStore.Currency" :disabled="!canEdit"></number-input>
                </div>
                <div class="form-group col col-input-sm service-row" v-if="recordStore.QuoteBy=='QUANTITY'">
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :addClass="{'border-warning': room.Rooms != Quantity}"
                        :v.sync="Quantity"
                        @change="setQuantity()"
                        :currency="recordStore.Currency"
                        v-if="quote" :disabled="!canEdit"
                    ></number-input>
                </div>
                <div class="form-group col col-input service-row" v-if="recordStore.QuoteBy=='QUANTITY'">
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :v="quote.TotalCost"
                        v-if="quote" :currency="recordStore.Currency" :disabled="true"></number-input>
                </div>
                <div class="form-group col col-input service-row" v-if="recordStore.QuoteBy=='QUANTITY'">
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :v="quote.TotalPrice"
                        v-if="quote" :currency="recordStore.Currency" :disabled="true"></number-input>
                </div>
                <div class="form-group col ml-2 mt-0 service-row" v-if="recordStore.QuoteBy=='QUANTITY'">
                    <div class="d-flex">
                        <select-input
                            class="mx-1 my-0"
                            fieldName="UnitCostBy"
                            :fieldOptions="costByOptions.UnitCostBy"
                            @change="change('UnitCostBy')"
                            addClass="max-100"
                            :currentValue.sync="quote.UnitCostBy">
                        </select-input>
                        <select-input
                            fieldName="PeriodCostBy"
                            :fieldOptions="costByOptions.PeriodCostBy"
                            @change="change('UnitCostBy')"
                            addClass="max-100"
                            class="mx-1 my-0"
                            :currentValue.sync="quote.PeriodCostBy">
                        </select-input>
                    </div>
                </div>
            </div>
        </div>
        <context-menu
            v-if="showMenu"
            :options="menuOptions"
            :ref="'quoteMenu' + _uid"
            @clicked="menuClicked"
        ></context-menu>
    </div>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'booking-quote-hotel-room',
    props: ['roomName', 'rooms', 'baseId', 'edit', 'roomIdx', 'room', 'dayNr', 'roomRow', 'dayId', '_key'],
    data () {
        return {
            currentDay: null,
            RoomCost: null,
            RoomPrice: null,
            Quantity: null,
            quote: null,
            costByOptions: {
                UnitCostBy: [
                    {value: 'ROOM', label: 'Room'},
                    {value: 'PERSON', label: 'Person'},
                ],
                PeriodCostBy: [
                    {value: 'NIGHT', label: 'Night'},
                    {value: 'PROGRAM', label: 'Stay'},
                ]
            },
            upOver: false,
            showMenu: false,
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            recordStore: state => state.main.record,
            hotelValues: state => state.booking.hotelValues,
        }),
        roomQuote () {
            return this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[this.roomIdx].QuoteList[this.baseId];
        },
        hotelCols () {
            if (this.recordStore.QuoteBy=='QUANTITY') return 'col-md-3';
            return 'col-md-6';
        },
        pricesCols () {
            if (this.recordStore.QuoteBy=='QUANTITY') return 'col-md-8';
            return 'col-md-5';
        },
        getPaxTypesByBase () {
            return this.recordStore.getPaxTypesByBase(this.baseId);
        },
        invalid () {
            return this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[this.roomIdx].QuoteList[this.baseId].invalid;
        },
        canEdit () {
            return this.salesActions;
        },
        canEditInclude () {
            return this.canEdit;
        },
        day () {
            return this.recordStore.BookingDays[this.dayId];
        },
        roomsCapacity () {
            if (!this.recordStore.BookingDays[this.dayId]) return;
            if (!this.recordStore.BookingDays[this.dayId].BookingDayHotels) return;
            if (!this.recordStore.BookingDays[this.dayId].BookingDayHotels[0]) return;
            if (!this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].roomsCapacity) return;
            if (!this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].roomsCapacity[this.baseId]) return;
            return this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].roomsCapacity[this.baseId];
        },
        roomsCapacityError () {
            if (this.room.TourLeader) return;
            if (this.room.SingleSupplement) return;
            if (!this.roomsCapacity) return;
            return this.roomsCapacity.error;
        },
        costValue () {
            let res = null;
            if (!this.room) return res;
            if (!this.room.Hotel) return res;
            if (!this.room.Room) return res;
            if (!this.room.RoomCategory) return res;
            if (!this.hotelValues[this.room.HotelId]) return res;
            if (!this.hotelValues[this.room.HotelId][this.room.Room.id]) return res;
            if (!this.hotelValues[this.room.HotelId][this.room.Room.id][this.room.RoomCategory]) return res;
            let hotelDate = this.recordStore.BookingDays[this.dayId].TransDate;
            if (!this.hotelValues[this.room.HotelId][this.room.Room.id][this.room.RoomCategory][hotelDate]) return res;
            if (!this.hotelValues[this.room.HotelId][this.room.Room.id][this.room.RoomCategory][hotelDate][this.roomQuote.days]) return res;
            return this.hotelValues[this.room.HotelId][this.room.Room.id][this.room.RoomCategory][hotelDate][this.roomQuote.days][this.baseId];
        },
        costError () {
            let costValue = this.costValue;
            if (this.quote && costValue && costValue > this.quote.Cost && this.quote.Include) {
                return true;
            }
        },
        date () {
            let date = this.recordStore.BookingDays[this.dayId].TransDate;
            if (!date) return "";
            return moment(date).locale(api.language).format('DD MMM')
        },
        menuOptions () {
            let roomName = this.roomName + ' '+ this.roomQuote.roomCategoryName;
            if (roomName && roomName.length > 30) {
                roomName = roomName.substring(0, 30) + '...';
            }
            let hotelName = this.roomQuote.hotelName;
            if (hotelName && hotelName.length > 30) {
                hotelName = hotelName.substring(0, 30) + '...';
            }
            return [
                {label: `${tr('Refresh price for room')} ${roomName}`, value: 'r'},
                {label: `${tr('Refresh prices for hotel')} ${hotelName}`, value: 'h'},
            ]
        },

    },
    async mounted () {
        await this.setQuote();
        this.setRoomValues();
        EventBus.$on('update-quotes', this.setQuote);
        EventBus.$on('update-quotes-hotels', this.setQuote);
    },
    methods: {
        setRoomValues () {
            this.RoomCost = parseFloat(this.roomQuote.Cost) * this.roomQuote.factor / (parseInt(this.roomQuote.days) * this.roomQuote.qFactor);
            this.RoomPrice = parseFloat(this.roomQuote.Price) * this.roomQuote.factor / (parseInt(this.roomQuote.days) * this.roomQuote.qFactor);
            this.Quantity = this.rooms;
            if (this.roomQuote.Rooms) this.Quantity = this.roomQuote.Rooms;
        },
        async setRoomCost () {
            await this.change('RoomCost');
        },
        editHotel () {
            this.$emit('editHotel');
        },
        closeHotelSelector () {
            appTools.closeHotelSelector(this);
        },
        getId () {
            if (!this.room) return;
            if (!this.room.Room) return;
            if (!this.room.RoomCategory) return;
            return 'hotel-day-' + this.roomQuote.dayNr + '-' + this.baseId + '-' + this.room.Room.id + '-' + this.room.RoomCategory;
        },
        async setQuote () {
            let error;
            if (!error && !this.recordStore.BookingDays[this.dayId]) error = true;
            if (!error && !this.recordStore.BookingDays[this.dayId].BookingDayHotels) error = true;
            if (!error && !this.recordStore.BookingDays[this.dayId].BookingDayHotels[0]) error = true;
            if (!error && !this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms) error = true;
            if (error) {
                api.post('/api/send_logs/', JSON.stringify({
                    'error': 'setQuote',
                    dayId: this.dayId,
                    roomIdx: this.roomIdx,
                    bookingId: this.recordStore.id,
                    baseId: this.baseId
                }));
                return;
            }
            if (!this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[this.roomIdx]) return;
            let row = this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[this.roomIdx];
            if (!row.QuoteList[this.baseId]) {
                row.QuoteList[this.baseId] = await row.QuoteList.newHotelQuote();
                row.QuoteList.getFields();
            }
            this.quote = Object.assign({}, this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[this.roomIdx].QuoteList[this.baseId]);
            if (this.quote.Rooms) this.Quantity = this.quote.Rooms;
        },
        async change (fieldName) {
            let value = this.quote[fieldName];
            await this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[this.roomIdx].QuoteList[this.baseId].setValue({fieldName, value});
            this.setQuote();
            EventBus.$emit('update-totals');
            let t2 = moment(new Date()).valueOf();
        },
        async setQuantity () {
            await this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[this.roomIdx].QuoteList[this.baseId].setValue({fieldName: 'Rooms', value: this.Quantity});
            this.setQuote();
            EventBus.$emit('update-totals');
        },
        setOver () {
            this.upOver = true;
        },
        setOverLeave () {
            this.upOver = null;
        },
        focusOn () {
            this.$emit('focusOn', this.dayId, this.roomIdx)
        },
        handleClickName (event) {
            event.preventDefault();
            this.showMenu = true;
            setTimeout(() => {
                let id = `quoteMenu${this._uid}`
                if (this.$refs[id]) {
                    this.$refs[id].showMenu(event, {});
                }
            }, 100);
        },
        handleClick (event) {
            this.$emit('handleClick', event, this.dayId, 'h');
        },
        async menuClicked (option, params) {
            if (option.value == 'r') {
                let hotelDate = this.day.TransDate;
                if (this.recordStore.SplitQuoteDate && this.recordStore.QuoteDate) {
                    hotelDate = this.day.QuoteDate;
                }
                let program;
                if (this.room && this.room.Category && this.room.Category.Program) program = this.room.RoomCategory;
                let hasProgramRooms = this.recordStore.hasProgramRooms(this.room.Hotel)
                this.recordStore.setHotelCost({
                    idx: this.dayId,
                    hotelId: this.room.HotelId,
                    hotelDate,
                    days: this.day.BookingDayHotels[0].Days,
                    roomNr: this.roomIdx,
                    baseId: this.baseId,
                    program,
                    hasProgramRooms,
                    //currencyId: this.room.CurrencyId,
                    skipCurrency: true,
                    updateModified: 2,
                })
                EventBus.$emit('update-totals');
            } else if (option.value == 'h') {
                await this.recordStore.setCostByDate({baseId: this.baseId, sync: true, supplierId: -1, hotelId: this.room.HotelId});
                EventBus.$emit('update-totals');
            } else {
                //this.$emit('menuClicked', option, params);
            }
            this.showMenu = false;
        }

    },
    beforeDestroy() {
        EventBus.$off('update-quotes', this.setQuote);
        EventBus.$off('update-quotes-hotels', this.setQuote);

    },
}
</script>


<template>
    <div v-if="user">
        <report-window
            v-if="ready"
            endpoint="/reports/servicecost"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :templates="templates"
            :hideFilters="options && options.hideFilters"
            :runMounted="options && options.runMounted"
            :cardView="options && options.cardView"
            :current.sync="current" :slotsFields="[]"
            :notFilterable="options && options.notFilterable"
            :hideBlankColumns="true"
            :beforeRender="beforeRender"
            :columnsClasses="columnsClasses"
        ></report-window>
    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
export default {
    name: "service-costlist-report",
    props: ["options"],
    components: {
        ReportWindow
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
        }),
    },
    mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data() {
        return {
            current: {},
            ready: false,
            costByOptions: {
                  BASE_PERSON: 'Per Person, variable on base',
                  PERSON: 'Per Person, fixed',
                  SERVICE: 'Per Service, fixed',
                  BASE_SERVICE: 'Per Service, variable on base'
            },
            fields: [
                {
                    name: "SupplierId",
                    relation: "supplier",
                    optionLabels: "Name",
                    editor: 'vue-select',
                    label: "Supplier",
                    addBlank: true,
                    required: true,
                    sort: true,
                    filters: (row) => {
                        if (row.SupplierType!='HOTEL') return true;
                    },
                },
                {
                    name: "CityId",
                    relation: "city",
                    optionLabels: "Name",
                    editor: 'vue-select',
                    label: "City",
                    addBlank: true,
                    sort: true,
                },
                {
                    name: "ServiceTypeId",
                    relation: "servicetype",
                    optionLabels: "Name",
                    editor: "select",
                    label: "Service Type",
                    addBlank: true,
                },
                {
                    name: "TransDate",
                    editor: "date",
                    label: "Date",
                },
                /*{
                    name: "Optionals",
                    editor: "checkbox",
                    label: "Optionals Only",
                },*/
                {
                    name: "OnlyCategories",
                    editor: "checkbox",
                    label: "With Category Only",
                },
                {
                    name: "Audit",
                    editor: "checkbox",
                    label: "Audit",
                },
            ],
            title: "Service Rates",
            headers: {
                SupplierName: "Supplier",
                ServiceName: "Service",
                StartDate: "Start Date",
                ServiceType: "Type",
                CurrencyId: "Currency",
                ServiceCategoryId: "Category",
                Comment: "Comments"
            },
            templates: [
                {
                    name: "Edited",
                    callback: (row) => {
                        if (!row.Edited) return "";
                        return moment(row.Edited).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "StartDate",
                    callback: (row) => {
                        if (!row.StartDate) return "";
                        return moment(row.StartDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "EndDate",
                    callback: (row) => {
                        if (!row.EndDate) return "";
                        return moment(row.EndDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "Cost",
                    callback: (row) => {
                        if (!row.Cost) return "";
                        return tr(this.costByOptions[row.Cost]);
                    }
                },
                {   name: 'Amount', callback: (row) => {
                        return tools.toNumber(row.Amount, 0, row.CurrencyId);
                    }
                },
            ],
            columnsClasses: {
            },

        };
    },
    methods: {
        beforeRender (report) {
            let headers = ['BlackOut', 'ChildDiscount', 'Closed', 'Comment', 'Cost', 'CreatedUTC', 'CreatedUserId', 'PeriodName',
              'CurrencyId', 'Data', 'Edited', 'EndDate', 'StartDate', 'ModifiedUTC', 'ModifiedUserId', 'Service', 'Audit',
              'ServiceCategoryId', 'ServiceId', 'ServiceName', 'ServiceType', 'id', 'syncVersion', 'Days', 'SupplierName'];
            if (report.serverColumnsHeader) {
                for (let column of report.serverColumnsHeader) {
                    if (headers.indexOf(column) == -1) {
                        let f = _.find(this.templates, (r) => r.name == column);
                        if (!f) {
                            this.templates.push({
                                name: column, callback: (row) => {
                                    return tools.toNumber(row[column], 0, row.CurrencyId);
                                }
                            })
                            this.columnsClasses[column] = 'text-right';
                        }
                    }
                }
                this.templates = Object.assign([], this.templates);
                this.columnsClasses = Object.assign({}, this.columnsClasses);
            }
        },
    }

};
</script>

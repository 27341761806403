import { library } from '@fortawesome/fontawesome-svg-core';
import { faStar as faStarReg, faClock as faClockReg } from '@fortawesome/free-regular-svg-icons';
import { faImage, faPlus, faMinusCircle, faMinus, faClipboard, faPlusCircle, faFolderOpen, faSave, faWindowClose,
    faTrashAlt, faHistory, faHdd, faPlusSquare, faClipboardList, faClipboardCheck, faDollarSign, faPen, faSync, faStar,
    faSquare, faEdit, faCommentDots, faTimes, faQuestionCircle, faPrint, faExclamation, faPlane, faEnvelope, faInfoCircle,
    faTicketAlt, faPaperclip, faExchangeAlt, faCheck, faCopy, faBars, faExclamationTriangle, faEyeSlash, faInfo, faAd,
    faFileWord, faFileUpload, faFilePdf, faPaste, faUsers, faBrush, faReceipt, faFileInvoice, faUser, faFileDownload,
    faHome, faWrench, faCloud, faCogs, faSignOutAlt, faList, faCog, faLanguage, faKey, faTools, faChartBar, faBell,
    faMoneyCheckAlt, faTag, faDonate, faExpand, faMobileAlt, faTabletAlt, faCompress, faHiking, faEye, faCalendarCheck,
    faMoneyBill, faCreditCard, faPassport, faBed, faHandHoldingUsd, faGlassMartiniAlt, faAlignLeft, faFont, faHotel,
    faSearch, faGripVertical, faLandmark, faBus, faCar, faTrain, faUtensils, faHSquare, faFilter, faPlay, faFileExcel,
    faFileCsv, faArrowsAlt, faTable, faArrowUp, faHeadset, faGlobe, faWindowMinimize, faWindowMaximize, faMapMarkerAlt,
    faTheaterMasks, faDivide, faStopwatch, faClock, faCircle, faExternalLinkAlt, faArrowCircleDown, faArrowDown,
    faRecycle, faMapMarkedAlt, faReply, faArrowsAltH, faCompressAlt, faUserEdit, faLock, faLockOpen, faLayerGroup,
    faMemory, faChevronRight, faChevronDown, faTrash}
    from '@fortawesome/free-solid-svg-icons';
import { faSkype, faWhatsapp, faGoogleDrive, faFacebookF, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faImage, faPlus, faMinusCircle, faMinus, faClipboard, faPlusCircle, faFolderOpen, faSave, faWindowClose,
    faTrashAlt, faHistory, faHdd, faPlusSquare, faClipboardList, faClipboardCheck, faDollarSign, faStar, faPen, faSync,
    faSquare, faEdit, faCommentDots, faTimes,  faQuestionCircle, faPrint, faExclamation, faPlane, faEnvelope, faInfoCircle,
    faTicketAlt, faSkype, faWhatsapp, faPaperclip, faGoogleDrive, faExchangeAlt, faCheck, faCopy, faBars, faInfo, faAd,
    faExclamationTriangle, faEyeSlash, faFileWord, faFileUpload, faFilePdf, faPaste, faUsers, faBrush, faReceipt, faCog,
    faFileInvoice, faUser, faFileDownload, faHome, faWrench, faStarReg, faCloud, faCogs, faSignOutAlt, faList,
    faLanguage, faKey, faTools, faChartBar, faMoneyCheckAlt, faTag, faDonate, faBell, faExpand, faMobileAlt, faTabletAlt,
    faCompress, faHiking, faEye, faCalendarCheck, faMoneyBill, faCreditCard, faPassport, faBed, faHandHoldingUsd,
    faGlassMartiniAlt, faAlignLeft, faFont, faHotel, faSearch, faGripVertical, faLandmark, faBus, faCar, faTrain,
    faUtensils, faHSquare, faFilter, faPlay, faFileExcel, faFileCsv, faArrowsAlt, faTable, faArrowUp, faHeadset,
    faGlobe, faWindowMinimize, faWindowMaximize, faMapMarkerAlt, faTheaterMasks, faDivide, faStopwatch, faClock,
    faCircle, faExternalLinkAlt, faArrowCircleDown, faArrowDown, faRecycle, faMapMarkedAlt, faReply, faClockReg,
    faFacebookF, faLinkedin, faInstagram, faArrowsAltH, faCompressAlt, faUserEdit, faLock, faLockOpen, faLayerGroup, 
    faMemory, faChevronRight, faChevronDown, faTrash);

<script>
const hotelSelectorModal = importVueComp('components/hotel', 'HotelSelectorModal', 'custom');

export default {
    name:'custom-hotel-selector-modal',
    mixins: [hotelSelectorModal],
    methods: {
        async select () {
            await this.recordStore.BookingDays[this.dayId].setHotel(this.selected, this.exchange);
            this.showSelector = false;
            await this.$nextTick();
            if (this.selected) {
                this.searchBox = null;
            } else {
                this.close();
            }
            if (this.selected.Review) alert(this.selected.Review)
        }
    }
}
</script>
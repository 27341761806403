<template>
        <report-window
            v-if="ready"
            endpoint="/reports/user_active_session"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :current.sync="current"
            :columnClick="columnClick"
            :templates="templates"
            :slotsFields="slotsFields"
            :updateReport="updateReport"
            :callbacks="callbacks"
        ></report-window>
</template>
<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
export default {
    name: "user-active-session",
    props: [],
    components: {
        ReportWindow,
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
        }),
    },
    async mounted () {
        this.getData()
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            updateReport: true,
            ready: false,
            fields: [],
            title: "User Actives Sessions",
            headers: {},
            templates: [],
            columnClick: {},
            slotsFields: [],
            callbacks: {},
            headerColumns: []
        };
    },
    methods:{
        getData () {
            this.slotsFields = [
                {name: 'CloseSession', editor: 'button', label: 'Close Session', icon: 'trash', classes: 'mb-0'},
            ]

            this.callbacks = {
                'CloseSession': async (fieldName, row, report) => {
                    let res = await api.remove(`/api/redis_by_key/`, row.key);
                    if (res.deteted) {
                        alert(`You are deleted the ${res.key} Session`)
                    }
                },
            }
            this.headerColumns = [
                "id",
                "UserName",
                "Email",
                "AccessGroup",
                "key",
                "CloseSession"
            ]
        }
    }
}
</script>
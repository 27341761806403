<template>
    <div v-if="showService">
        <div class="service-row mr-2" :class="serviceCols">
            <label @click="serviceClick" :class="serviceClass" class="pr-3" @contextmenu="handleClickName($event)">
                <strong v-if="recordStore.BookingDays[dayId].BookingDayServices[i].Optional">
                    <i>{{tr('Optional')}}</i>
                </strong>
                {{serviceName}}
                {{recordStore.BookingDays[dayId].BookingDayServices[i].serviceTypeCategory}}
                {{recordStore.BookingDays[dayId].BookingDayServices[i].supplierNameQuote}}
                <i class="text-danger" v-if="recordStore.BookingDays[dayId].BookingDayServices[i].Status=='CANCELED'">
                  {{tr('Canceled')}}
                </i>
            </label>
        </div>
        <div class="d-flex justify-content-center form-row" :class="pricesCols" @click="focusOn">
            <div v-for="pType of getPaxTypesByBase" class="form-row" :class="paxCols" v-if="quote && quote[pType]">
                <div class="form-group col service-row col-input">
                    <input v-model="quote[pType].Include"
                        type="checkbox" class="form-check-input"
                        @change="change('Include', pType)"
                        @contextmenu="handleClick($event)"
                        v-if="edit || (service && service.Optional)" :disabled="!canEdit"/>
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :v.sync="quote[pType].Cost"
                        :addClass="{'border-danger': quote[pType].invalid.Cost, 'border-warning': quote[pType].Modified, 'line-through': recordStore.BookingDays[dayId].BookingDayServices[i].skipQuoteCanceled}"
                        :id="baseId + '-' + recordStore.BookingDays[dayId].BookingDayServices[i].dayNr + '-' + i + '-' + pType + '-Cost'"
                        v-if="quote[pType]"
                        :currency="recordStore.Currency" :disabled="!canEdit"
                        @change="change('Cost', pType)"
                    ></number-input>
                    <i class="cost-alert cursor-pointer" v-if="costError[pType]" >
                      <font-awesome-icon icon="exclamation" @click="setOver(pType)"/>
                      <div v-if="upOver==pType" @click="setOverLeave" class="cost-value cursor-pointer">
                          {{tr('Current cost')}}: {{costValue[pType] | formatNumber({c: recordStore.Currency}) }}
                      </div>
                    </i>
                </div>
                <div class="form-group col service-row col-input">
                    <number-input
                        :changeStyle="true"
                        :class="{'line-through': recordStore.BookingDays[dayId].BookingDayServices[i].skipQuoteCanceled}"
                        class="my-0 mx-1"
                        :v.sync="quote[pType].Markup"
                        :addClass="quote[pType].MarkupModified? 'border-warning': ''"
                        v-if="quote[pType]"
                        @change="change('Markup', pType)"
                        :currency="recordStore.Currency" :disabled="!canEdit"
                    ></number-input>
                </div>
                <div class="form-group col service-row col-input">
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :v.sync="quote[pType].Price"
                        :id="baseId + '-' + recordStore.BookingDays[dayId].BookingDayServices[i].dayNr + '-' + i + '-' + pType + '-Price'"
                        :addClass="{'border-danger': quote[pType].isErrorPrice, 'border-warning': quote[pType].PriceModified, 'border-danger': quote[pType].invalid.Price, 'line-through': recordStore.BookingDays[dayId].BookingDayServices[i].skipQuoteCanceled}"
                        v-if="quote[pType]"
                        :disabled="!canEdit"
                        :currency="recordStore.Currency"
                        @change="change('Price', pType)"
                    ></number-input>
                </div>
                <div class="form-group col service-row col-input">
                    <number-input
                        :changeStyle="true"
                         v-if="edit"
                        :v.sync="quote[pType].Base"
                        class="my-0 mx-1"
                        :disabled="!canEdit"
                        @change="change('Base', pType)"
                    ></number-input>
                </div>
                <div class="form-group col service-row col-input" v-if="recordStore.QuoteBy=='QUANTITY'">
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :v.sync="quote[pType].Quantity"
                        @change="change('Quantity', pType)"
                        :disabled="!canEdit">
                    </number-input>
                </div>
                <div class="form-group col service-row col-input" v-if="recordStore.QuoteBy=='QUANTITY'">
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :v.sync="quote[pType].TotalCost"
                        :currency="recordStore.Currency" :disabled="true">
                    </number-input>
                </div>
                <div class="form-group col service-row col-input" v-if="recordStore.QuoteBy=='QUANTITY'">
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :v.sync="quote[pType].TotalPrice"
                        :currency="recordStore.Currency" :disabled="true">
                    </number-input>
                </div>
            </div>
        </div>
        <context-menu
            v-if="showMenu"
            :options="menuOptions"
            :ref="'quoteMenu' + _uid"
            @clicked="menuClicked"
        ></context-menu>
    </div>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'booking-quote-service',
    props: ['baseId', 'i', 'dayId', 'dayNr', 'edit'],
    data () {
        return {
            updating: null,
            hotelSelector: false,
            currentDay: null,
            bookingCityId: null,
            hotelInfo: {},
            bookingCityName: null,
            currentBaseId: null,
            quote: {},
            upOver: null,
            showMenu: null,
        }
    },
    mounted () {
        this.setValues();
        EventBus.$on('update-quotes', this.setValues);
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            recordStore: state => state.main.record,
            costValues: state => state.booking.costValues,
        }),
        canEdit () {
            return this.salesActions;
        },
        serviceName () {
            return this.recordStore.BookingDays[this.dayId].BookingDayServices[this.i].serviceName;
        },
        supplierNameQuote () {
            return this.recordStore.BookingDays[this.dayId].BookingDayServices[this.i].supplierNameQuote;
        },
        service () {
            return this.recordStore.BookingDays[this.dayId].BookingDayServices[this.i];
        },
        menuOptions () {
            let serviceName = this.serviceName;
            if (serviceName && serviceName.length > 30) {
                serviceName = serviceName.substring(0, 30) + '...';
            }
            return [
                {label: `${tr('Refresh price for service')} ${serviceName}`, value: 's'},
                {label: `${tr('Refresh prices for supplier')} ${this.supplierNameQuote}`, value: 'p'},
            ]
        },
        costValue () {
            let res = {};
            if (!this.service.ServiceId) return res;
            let id = this.service.ServiceId;
            let categoryId = this.service.Service.ServiceCategoryId;
            if (!this.costValues[[id, categoryId]]) return res;
            if (!this.costValues[[id, categoryId]][this.service.ServiceDate]) return res;
            if (!this.costValues[[id, categoryId]][this.service.ServiceDate][this.baseId]) return res;
            for (let pType of this.getPaxTypesByBase) {
                if (this.costValues[[id, categoryId]][this.service.ServiceDate][this.baseId][pType]) {
                    let costValue = this.costValues[[id, categoryId]][this.service.ServiceDate][this.baseId][pType];
                    if (costValue > this.quote[pType].Cost &&  this.quote[pType].Include) {
                        res[pType] = costValue;
                    }
                }
            }
            return res;
        },
        costError () {
            let res = {};
            for (let pType of this.getPaxTypesByBase) {
                let costValue = this.costValue[pType];
                let decimals = this.recordStore.Currency.Decimals;
                let decimalNull = tools.isNull(decimals);
                if (decimalNull) decimals = 2;
                if (costValue && this.quote[pType].Include && this.quote[pType].Cost) {
                    costValue = parseFloat(costValue.toFixed(decimals));
                    let quoteValue = parseFloat(this.quote[pType].Cost.toFixed(decimals));
                    if (costValue > quoteValue) {
                        res[pType] = true;
                    }
                }
            }
            return res;
        },
        showService () {
            if (this.service.Service && this.service.Service.NotPrice) return false;
            if (this.service.Optional) return true;
            let found = false;
            if (this.quote) {
                for (let p in this.quote) {
                    if (this.quote[p] && this.quote[p].Include) found = true;
                }
            }
            if (found || this.edit) {
                return true;
            }
            return false;
        },
        getPaxTypesByBase () {
            return this.recordStore.getPaxTypesByBase(this.baseId);
        },
        getFirstToShow () {
            if (!this.recordStore) return;
            if (!this.recordStore.BookingDays[this.dayId]) return;
                for (let s of this.recordStore.BookingDays[this.dayId].BookingDayServices) {
                let found = false;
                if  (s.Quote.QuoteList) {
                    for (let b in s.Quote.QuoteList) {
                        for (let p in s.Quote.QuoteList[b]) {
                            if  (s.Quote.QuoteList[b][p] && s.Quote.QuoteList[b][p].Include) {
                                if (this.service.OrderNr == s.OrderNr) {
                                  return true;
                                }
                                return false;
                            };
                        }
                    }
                }
            }
        },
        serviceCols () {
            if (this.recordStore.QuoteBy=='QUANTITY') return 'col-md-5';
            if (this.getPaxTypesByBase.length==2) return 'col-md-5';
            return 'col-md-7';
        },
        pricesCols () {
            if (this.recordStore.QuoteBy=='QUANTITY') return 'col-md-6';
            if (this.getPaxTypesByBase.length==2) return 'col-md-7';
            return 'col-md-5';
        },
        paxCols () {
            if (this.getPaxTypesByBase.length==2) return 'col-md-6';
            return 'col-md-12';
        },
        date () {
            let date = this.recordStore.BookingDays[this.dayId].TransDate;
            if (!date) return "";
            return moment(date).locale(api.language).format('DD MMM')
        },
        serviceClass () {
            return '';
        }
    },
    methods: {
        async setValues () {
            let quoteList = this.recordStore.BookingDays[this.dayId].BookingDayServices[this.i].Quote.QuoteList
            if (!quoteList[this.baseId]) {
                let service = this.recordStore.BookingDays[this.dayId].BookingDayServices[this.i];
                await this.recordStore.getServicePersonCostRow({baseQuoteId: this.baseId, service});
            }
            this.quote = Object.assign({}, this.recordStore.BookingDays[this.dayId].BookingDayServices[this.i].Quote.QuoteList[this.baseId]);
        },
        async change (fieldName, pType) {
            let value = this.quote[pType][fieldName];
            await this.recordStore.BookingDays[this.dayId].BookingDayServices[this.i].Quote.QuoteList[this.baseId][pType].setValue({fieldName, value});
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
        },
        setOver (pType) {
            this.upOver = pType;
        },
        setOverLeave () {
            this.upOver = null;
        },
        focusOn () {
            this.$emit('focusOn', this.dayId, this.i)
        },
        serviceClick () {
        },
        handleClickName (event) {
            event.preventDefault();
            this.showMenu = true;
            setTimeout(() => {
                let id = `quoteMenu${this._uid}`
                if (this.$refs[id]) {
                    this.$refs[id].showMenu(event, {});
                }
            }, 100);
        },
        handleClick (event) {
            this.$emit('handleClick', event, this.dayId, 's');
        },
        async menuClicked (option, params) {
            if (option.value == 's') {
                let quoteDate = this.service.ServiceDate;;
                if (this.recordStore.SplitQuoteDate && this.recordStore.QuoteDate) {
                    quoteDate = this.service.$parent.QuoteDate;;
                }
                this.recordStore.getServiceCost({baseQuoteId: this.baseId, service: this.service, quoteDate: quoteDate, updateModified: 2});
                EventBus.$emit('update-totals');
            } else if (option.value == 'p') {
                await this.recordStore.setCostByDate({baseId: this.baseId, sync: true, supplierId: this.service.SupplierId, hotelId: -1});
                EventBus.$emit('update-totals');
            } else {
                //this.$emit('menuClicked', option, params);
            }
            this.showMenu = false;
        }

    },
    beforeDestroy() {
        EventBus.$off('update-quotes', this.setValues);
    },
}
</script>

<template>
    <div class="col-11" v-if="ready">
        <div class="section-title">
            <label>{{tr('Summary')}}</label>
        </div>
        <div v-if="recordStore.QuoteBy!='QUANTITY'">
            <div class="form-row">
                <label class="col"></label>
                <label class="col"></label>
                <label class="col text-center">{{tr('Rooms')}}</label>
                <label class="col text-right">{{tr('Price per Person')}}</label>
                <label class="col text-right" v-if="anySingleSup">{{tr('SGL SUP')}}</label>
                <label class="col text-right" v-if="flightsNotIncluded">{{tr('Flights per Person')}}</label>
                <label class="col text-right">{{tr('Total')}}</label>
                <label class="col text-right" v-if="finalTotalFlights">{{tr('With Flights')}}</label>
                <label class="col text-right" >{{tr('Profit')}}</label>
            </div>
            <div class="form-row">
                <div class="col-12">
                    <hr class="my-1">
                </div>
            </div>
            <div v-for="(quote, baseId) in recordStore.bases">
                <div v-for="pType of paxTypesByBase[baseId]" >
                    <div class="form-row" v-if="totals && Totals[baseId] && Totals[baseId][pType] && Totals[baseId][pType].Selected">
                        <label class="col"><span v-if="Totals[baseId][pType].Comment">{{Totals[baseId][pType].Comment}}</span></label>
                        <label class="col">{{paxBaseByPaxType[baseId][pType]}} {{pType}}</label>
                        <label class="col text-center" v-if="totals[baseId][pType].Rooms">{{pType}} {{tr('Room')}} {{getRoomsNames(totals[baseId][pType].Rooms)}} </label>
                        <label class="col text-center" v-else>{{pType}} </label>
                        <label class="col text-right">{{parseFloat(getTotalInCurrency(totals[baseId][pType].Price)) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                        <label class="col text-right" v-if="anySingleSup">
                            <span v-if="quote.SingleSupplement">{{parseFloat(getTotalInCurrency(singleSupplementValue[baseId])) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</span>
                        </label>
                        <label class="col text-right" v-if="flightsNotIncluded">
                            <span v-if="totalFlightsNotIncludedAll[baseId] && totalFlightsNotIncludedAll[baseId][pType]">{{getTotalInCurrency(totalFlightsNotIncludedAll[baseId][pType].Price) | formatNumber({c: recordStore.PriceCurrency, s: 1}) }}</span>
                        </label>
                        <label class="col text-right">{{totalByBasePaxType[baseId][pType].Price | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                        <label class="col text-right" v-if="finalTotalFlights">{{totalByBasePaxTypeWithFlights[baseId][pType].Price | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>

                        <label class="col text-right">{{totalByBasePaxTypeWithFlights[baseId][pType].Profit | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                    </div>
                </div>
            </div>
            <div v-for="row of recordStore.BookingExpenses" >
                <div class="form-row" v-if="row.Type=='PAX' && row.Amount && row.CurrencyId">
                    <label class="col-3 offset-7">{{getExpenseName(row.ExpenseTypeId)}}</label>
                    <label class="col-2 text-right">{{currencyConvert(row.CurrencyId, row.Amount) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                </div>
            </div>
            <div class="form-row">
                <div class="col-12">
                    <hr class="my-1">
                </div>
            </div>
            <div class="form-row">
                <label class="col"></label>
                <label class="col"></label>
                <label class="col"></label>
                <label class="col text-right">Neto:</label>
                <label class="col  text-right" v-if="finalTotalFlights"></label>
                <label class="col text-right">{{stdFinalTotal | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                <label class="col text-right" v-if="finalTotalFlights">{{stdFinalTotalWithFlights | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                <label class="col text-right">{{ totalProfitWithFlights | formatNumber({c: recordStore.PriceCurrency, s:1})}}</label>
            </div>
            <div class="form-row" v-if="recordStore.AgencyCommission || recordStore.PaxTax">
                <label class="col"></label>
                <label class="col"></label>
                <label class="col"></label>
                <label class="col text-right">Comisionable:</label>
                <label class="col  text-right" v-if="finalTotalFlights"></label>
                <label class="col text-right">{{finalTotal | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                <label class="col text-right" v-if="finalTotalFlights">{{finalTotalWithFlights | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                <label class="col text-right"></label>
            </div>
        </div>
        <div v-else>
            <div class="form-row">
                <label class="col"></label>
                <label class="col"></label>
                <label class="col text-right" v-if="recordStore.ShowPriceBy=='PERSON'">{{tr('Price per Person')}}</label>
                <label class="col text-right" v-if="recordStore.ShowPriceBy=='PERSON' && anySingleSup">{{tr('SGL SUP')}}</label>
                <label class="col text-right">{{tr('Total')}}</label>
                <label class="col text-right" v-if="finalTotalFlights">{{tr('With Flights')}}</label>
            </div>

            <div v-for="(quote, baseId) in recordStore.BaseQuotes.bases">
                <div v-for="(pType, pid) of paxTypesByBase[baseId]" class="">
                    <div class="form-row border-top" v-if="Totals[baseId] && Totals[baseId][pType] && Totals[baseId][pType].Selected">
                        <label class="col" v-if="Totals[baseId][pType].Comment">{{Totals[baseId][pType].Comment}}</label>
                        <label class="col">{{paxBaseByPaxType[baseId][pType]}} {{pType}}</label>
                        <label class="col" v-if="totals[baseId][pType].Rooms">{{pType}} {{tr('Room')}} {{getRoomsNames(totals[baseId][pType].Rooms)}} </label>
                        <label class="col" v-else>{{pType}}</label>
                        <label class="col text-right" v-if="recordStore.ShowPriceBy=='PERSON'">
                            {{parseFloat(getTotalInCurrency(totalsPerPerson[baseId][pType].Price)) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}
                        </label>
                        <label class="col text-right" v-if="recordStore.ShowPriceBy=='PERSON' && anySingleSup">
                            <span v-if="quote.SingleSupplement">{{parseFloat(getTotalInCurrency(singleSupplementValue[baseId])) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</span>
                        </label>

                        <label class="col text-right">{{parseFloat(getTotalInCurrency(totals[baseId][pType].Price)) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                        <label class="col text-right" v-if="finalTotalFlights">{{totalByBasePaxTypeWithFlights[baseId][pType].Price | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>

                    </div>
                    <div v-if="singleSupplementValue[baseId] && totalsPerPerson && totalsPerPerson[baseId] && totalsPerPerson[baseId][pType] && Object.keys(totals[baseId][pType].Rooms).length > 1">
                        <div v-for="room of totalsPerPerson[baseId][pType].Rooms" class="form-row">
                            <label class="col" v-if="Totals[baseId][pType].Comment"></label>
                            <label class="col">{{room.pax}} {{pType}}</label>
                            <label class="col" v-if="room.Room">{{room.Room.Name}}</label>
                            <label class="col" v-else></label>
                            <label class="col text-right" v-if="recordStore.ShowPriceBy=='PERSON'">
                                {{parseFloat(getTotalInCurrency(room.Price)) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}
                            </label>
                            <label class="col text-right" v-if="recordStore.ShowPriceBy=='PERSON' && anySingleSup">
                            </label>
                            <label class="col text-right"></label>
                            <label class="col text-right" v-if="finalTotalFlights"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div v-for="row of recordStore.BookingExpenses" >
                <div class="form-row" v-if="row.Type=='PAX' && row.Amount && row.CurrencyId">
                    <label class="col">{{getExpenseName(row.ExpenseTypeId)}}</label>
                    <label class="col text-right">{{currencyConvert(row.CurrencyId, row.Amount) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                </div>
            </div>

            <div class="form-row border-top">
                <label class="col text-right">{{tr('TOTAL')}}:</label>
                <label class="col text-right">{{finalTotal | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
                <label class="col text-right" v-if="finalTotalFlights">{{finalTotalWithFlights | formatNumber({c: recordStore.PriceCurrency, s: 1})}}</label>
            </div>
        </div>
    </div>
</template>

<script>
const bookingQuoteSummary = importVueComp('components/booking', 'BookingQuoteSummary', 'custom');
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'custom-booking-quote-summary',
    mixins: [bookingQuoteSummary],
    data () {
        return {
            stdFinalTotal: 0,
            stdFinalTotalWithFlights: 0,
            totalProfitWithFlights: 0
        }
    },
    mounted () {
        this.setTotals2();
        EventBus.$on('update-totals', this.setTotals2);
    },
    computed:{

    },
    methods:{
        setTotals2 () {
            this.stdFinalTotal = this.getTotalInCurrency(this.recordStore.stdFinalTotal)
            this.stdFinalTotalWithFlights = this.getTotalInCurrency(this.recordStore.stdFinalTotalWithFlights)
            this.totalProfitWithFlights = this.getTotalInCurrency(this.recordStore.totalProfitWithFlights)
        },
        getTotalByBasePaxTypeProfit(baseId, pType){
            return this.totals[baseId][pType].Price - this.totals[baseId][pType].Cost
        }
        
    }
}    

</script>

